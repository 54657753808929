.ProseMirror {
    table {
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
        margin: 0;
        overflow: hidden;
        td,
        th {
            min-width: 1em;
            border: 1px solid #cbd5e1; //slate-300
            padding: 0.25rem;
            vertical-align: top;
            box-sizing: border-box;
            position: relative;
            >* {
                margin: 0;
                padding: 0.25rem;
            }
        }
        th {
            font-weight: bold;
            text-align: left;
            background-color: #f3f4f6; //gray-200
        }
        .selectedCell:after {
            background: rgba(200, 200, 255, 0.4);
            content: '';
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            pointer-events: none;
            position: absolute;
            z-index: 2;
        }
        .column-resize-handle {
            position: absolute;
            right: -2px;
            top: 0;
            bottom: -2px;
            width: 4px;
            background-color: #adf;
            pointer-events: none;
        }
    }
}

.ProseMirror .column-block {
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    gap: 24px;
    padding: 8px 0;
}

.ProseMirror .column {
    overflow: auto;
    border: 1px gray dashed;
    border-radius: 8px;
    padding: 8px;
    margin: -8px;
}


/* Placeholder (at the top) */

.ProseMirror p.is-editor-empty:first-child::before {
    color: #cbd5e1;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
}


/* Placeholder (on every new line) */

.ProseMirror .is-node-empty::before {
    color: #cbd5e1;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
}

ul[data-type='taskList'] {
    list-style: none;
    padding: 0;
    li {
        display: flex;
        align-items: center;
        >label {
            flex: 0 0 auto;
            padding: 0.375rem;
            padding-left: 0;
            margin-bottom: auto;
            user-select: none;
        }
        >div {
            flex: 1 1 auto;
            >p {
                margin-bottom: 0;
                font-size: 1.25rem;
            }
        }
    }
    input[type='checkbox'] {
        appearance: none;
        outline: none;
        display: block;
        position: relative;
        text-align: center;
        cursor: pointer;
        width: 20px;
        height: 20px;
        margin: 0 auto;
        border-radius: 3px;
    }
    // チェックボックスのデザイン
    input[type='checkbox']::before {
        display: block;
        position: absolute;
        content: '';
        width: 20px;
        height: 20px;
        background: #fff;
        border: solid 1px #cbd5e1;
        border-radius: 3px;
    }
    // チェックボックスの背景をチェック後に変更
    input[type='checkbox']:checked::before {
        background-color: #0d6efd;
        border: solid 1px #0d6efd;
    }
    // チェックマークのデザイン
    input[type='checkbox']::after {
        display: block;
        content: '';
        position: absolute;
        left: 7px;
        top: 2px;
        width: 7px;
        height: 14px;
        border-right: 3px solid #fff;
        border-bottom: 3px solid #fff;
        transform: rotate(45deg);
        // チェックしてないときは隠す
        opacity: 0;
        border-radius: 1px;
    }
    // チェックするとチェックマークの透明化を解除
    input[type='checkbox']:checked::after {
        opacity: 1;
    }
}

.items {
    padding: 0.2rem;
    position: relative;
    border-radius: 0.5rem;
    background: #fff;
    color: rgba(0, 0, 0, 0.8);
    overflow: hidden;
    font-size: 0.9rem;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.item {
    display: block;
    margin: 0;
    width: 100%;
    text-align: left;
    background: transparent;
    border-radius: 0.4rem;
    border: 1px solid transparent;
    padding: 0.2rem 0.4rem;
    &.is-selected {
        border-color: #000;
    }
}

// default 65ch 2022-1114
.prose {
    max-width: unset !important;
}

// listのLine間隔が大きい 2022-1215
// B/M bloomberg
.prose ul>li>p {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.prose ul>ol>p {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

// react-element配下のイメージ prose-lg/imgの設定で、上下Margenがある。2022-1210
.react-element {
    img {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        margin-top: 0.125rem !important;
        margin-bottom: 0.125rem !important;
        border-radius: 0.125rem !important;
    }
    img:hover {
        -webkit-transform: scale(1.025);
        transform: scale(1.025);
    }
}

// 2023-0113
// .has-focus {
//     border-radius: 3px;
//     box-shadow: 0 0 0 2px #68cef8;
// }