#spotlight {
    // position: fixed !important;
    // top: 0 !important;
    // right: 0 !important;
    // left: 0 !important;
    // bottom: 0 !important;
    background-color: rgba(55, 55, 55, 0.85) !important;
    // height: 100% !important;
    // filter: alpha(opacity=50) !important;
    // z-index: 3000 !important;
}