.bordered-column {
    th,
    td {
        border-right: 1px solid #e5e7eb;
         :last-child {
            border-right: 0;
        }
    }
}

.table-striped {
    tbody tr:nth-of-type(odd) {
        background-color: #f3f4f6;
    }
}

.su-resizer {
    position: absolute;
    right: -2px;
    top: 0;
    height: 100%;
    width: 5px;
    background: #94a3b8;
    cursor: col-resize;
    user-select: none;
    touch-action: none;
}

.su-resizer.isResizing {
    background: #38bdf8;
    opacity: 1;
}

@media (hover: hover) {
    .su-resizer {
        opacity: 0;
    }
    *:hover>.su-resizer {
        opacity: 1;
    }
}