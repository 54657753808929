/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap'); */

/* @import url('https://fonts.cdnfonts.com/css/super-famifont'); */

@font-face {
  font-family: 'Super FamiFont';
  src: url('../fonts/Super FamiFont.ttf') format('opentype'),
    url('../fonts/Super FamiFont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
  .su-code {
    @apply bg-slate-200 text-slate-600;
    /* color: var(--tw-prose-pre-code);
        background-color: var(--tw-prose-pre-bg); */
  }
  .bubble-menu {
    @apply flex w-80 items-center overflow-x-auto rounded-md border-slate-300 bg-white p-0.5 shadow-lg;
  }
  .bubble-memu-table-cell {
    @apply flex items-center overflow-x-auto rounded-sm border-slate-300 bg-white p-0.5 shadow-sm;
  }
  .bubble-memu-table-cell.row {
    @apply flex translate-x-3/4 flex-col gap-2;
  }
  .btn-submit {
    @apply flex w-full cursor-pointer items-center justify-center rounded-sm bg-gradient-to-r from-cyan-500 to-blue-500 px-4 py-1.5 text-base font-medium text-white shadow-lg shadow-blue-400/40 duration-300 ease-in;
  }
  .btn-submit:hover {
    @apply shadow-64px shadow-blue-500/40;
  }
  .btn-submit.disabled {
    @apply bg-slate-100 text-white;
  }
  .btn-subscribe {
    @apply flex w-full cursor-pointer items-center justify-center rounded-sm bg-indigo-500 px-4 py-1.5 text-base font-medium text-white shadow-lg shadow-indigo-400/40 duration-300 ease-in;
  }
  .btn-subscribe:hover {
    @apply shadow-64px shadow-indigo-500/40;
  }
  .btn-subscribe.disabled {
    @apply bg-slate-100 text-white shadow-none;
  }
  .btn-warning {
    @apply flex w-full cursor-pointer items-center justify-center rounded-sm bg-gradient-to-r from-orange-400 to-rose-400 px-4 py-1 text-base font-medium text-white duration-300 ease-in;
  }
  .btn-warning:hover {
    @apply shadow-64px shadow-orange-500/30;
  }
  .svg-5 {
    @apply h-5 w-5;
  }
  .svg-6 {
    @apply h-6 w-6;
  }
  .su-logo {
    @apply font-superfami bg-gradient-to-r from-indigo-500 to-rose-500 bg-clip-text text-logo font-black uppercase tracking-tight text-transparent;
  }
  .su-logo-jp {
    @apply font-superfami bg-gradient-to-r from-rose-500 to-rose-500 bg-clip-text text-sm font-black uppercase text-transparent;
  }
  .h-spacepage {
    height: calc(100vh - 3rem);
  }
  .min-h-spacepage {
    max-height: calc(100vh - 3rem);
  }
  .max-spacepage {
    max-height: calc(100vh - 3rem);
  }
  .h-mainpage {
    height: calc(100vh - 5rem);
  }
  .min-h-mainpage {
    max-height: calc(100vh - 5rem);
  }
  .max-mainpage {
    max-height: calc(100vh - 5rem);
  }
  /* react-icons */
  .tw-icon {
    @apply z-10 h-5 w-5 cursor-pointer text-slate-400 transition-transform delay-150 ease-in-out;
  }
  .tw-icon.disabled,
  .tw-icon:disabled {
    @apply fill-slate-300 text-slate-300;
  }
  .tw-icon:hover {
    @apply scale-110 text-slate-600;
  }
  .tw-icon6 {
    @apply tw-icon h-6 w-6;
  }
  .tw-icon4 {
    @apply tw-icon h-4 w-4;
  }
  /* react-icons */
  .su-icon {
    @apply z-10 h-5 w-5 cursor-pointer text-indigo-400 transition-transform delay-150 ease-in-out;
  }
  .su-icon.disabled,
  .su-icon:disabled {
    @apply fill-slate-300 text-slate-300;
  }
  .su-icon:hover {
    @apply scale-110 text-indigo-600;
  }
  .su-icon-danger {
    @apply z-10 h-5 w-5 cursor-pointer text-red-500 transition-transform delay-150 ease-in-out;
  }
  .su-icon-danger.disabled,
  .su-icon-danger:disabled {
    @apply fill-red-300 text-red-300;
  }
  .su-icon-danger:hover {
    @apply scale-110 text-red-600;
  }
  .su-icon6 {
    @apply su-icon h-6 w-6;
  }
  .su-icon4 {
    @apply su-icon h-4 w-4;
  }
  .su-title {
    @apply text-lg font-medium text-slate-500;
  }
  .su-label {
    @apply text-base font-normal text-slate-500;
  }
  .su-menu-title {
    @apply text-sm font-normal text-slate-600;
  }
  .su-text-value {
    @apply block rounded-sm border bg-slate-50 px-3 py-1 text-base font-normal text-slate-500;
  }
  .su-remark {
    @apply bg-gradient-to-r from-indigo-400 to-purple-600 bg-clip-text text-sm font-semibold text-transparent;
  }
  .su-gradient {
    @apply bg-gradient-to-r from-purple-400 to-pink-600 bg-clip-text text-lg font-extrabold text-transparent;
  }
  .su-disabled {
    @apply border-slate-200 bg-slate-100 text-base font-normal text-slate-600;
  }
  .su-scrollbar {
    @apply overflow-y-scroll scrollbar-thin scrollbar-track-slate-100 scrollbar-thumb-slate-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-full;
  }
  .su-scrollbar-transparent {
    @apply overflow-y-scroll scrollbar-thin scrollbar-track-transparent scrollbar-thumb-slate-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-full;
  }
  .su-dragpanel {
    @apply fixed z-10 bg-slate-500 shadow-64px shadow-gray-500/50;
  }
  .su-dragpanel-close {
    @apply absolute -right-3 -top-3 z-20 h-8 w-8 cursor-pointer rounded-full bg-slate-200 p-2 text-slate-400 shadow-64px shadow-slate-900;
  }
  .su-dragpanel-handle {
    @apply cursor-grab;
  }
  .flex-betcenter {
    @apply flex items-center justify-between;
  }
  .flex-startcenter {
    @apply flex items-center justify-start;
  }
  .flex-endcenter {
    @apply flex items-center justify-end;
  }
  .flex-centercenter {
    @apply flex items-center justify-center;
  }
  .trans-eio-300 {
    @apply transition duration-300 ease-in-out;
  }
  .trans-eio-500 {
    @apply transition duration-500 ease-in-out;
  }
  .text-gradient {
    @apply bg-gradient-to-r from-pink-500 to-violet-500 bg-clip-text text-transparent;
  }
  .tw-button {
    @apply trans-eio-300 cursor-pointer rounded-full bg-white px-4 py-1.5 text-sm font-medium leading-5 text-gray-700 shadow-4px shadow-slate-400/40;
  }
  .tw-button:hover:not(.disabled) {
    @apply bg-slate-50 shadow-8px shadow-slate-500/50;
  }
  .tw-button:focus {
    @apply outline-none ring-2 ring-indigo-500 ring-offset-2;
  }
  .tw-button.disabled {
    @apply bg-slate-200 text-slate-500;
  }
  .su-button {
    @apply trans-eio-500 flex-centercenter cursor-pointer rounded-full border border-gray-300 bg-white from-indigo-500 px-6 py-1.5 text-sm font-medium leading-5 text-slate-500 shadow-sm;
  }
  .su-button:hover:not(.disabled),
  .su-button.active {
    @apply border-transparent bg-gradient-to-r from-indigo-400 to-purple-400 text-white;
  }
  .su-button:focus {
    @apply outline-none ring-2 ring-indigo-500 ring-offset-2;
  }
  .su-button.disabled {
    @apply bg-slate-200 text-slate-500;
  }
  /* clickできるbox 2022-1222 */
  .su-click-box {
    @apply trans-eio-300 flex-betcenter w-full cursor-pointer rounded-lg p-3 shadow-8px shadow-slate-300/30;
  }
  .su-click-box:hover {
    @apply shadow-16px shadow-slate-400/40;
  }
  .su-hover-box {
    @apply border border-slate-100 shadow-16px shadow-slate-300/30 transition-shadow duration-300 ease-linear;
  }
  .su-hover-box:hover {
    @apply shadow-32px shadow-slate-400/50;
  }
}

/*
@layer utilities {
    .su-grid-elements {
        @apply grid grid-cols-2 gap-x-4 gap-y-8 sm: grid-cols-3 sm: gap-x-4 xl: grid-cols-4 xl: gap-x-6 2xl: grid-cols-5 2xl: gap-x-6;
    }
} */

@layer base {
  button:disabled,
  button.disabled {
    background-color: #94a3b8 !important;
  }
}

/* super fami 2022-0919 */

.font-superfami {
  font-family: 'Super FamiFont';
}

:root {
  --black-gradient: linear-gradient(144.39deg, #ffffff -278.56%, #6d6d6d -78.47%, #11101d 91.61%);
  --card-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

* {
  scroll-behavior: smooth;
}

.bg-blue-gradient {
  background: linear-gradient(
    157.81deg,
    #def9fa -43.27%,
    #bef3f5 -21.24%,
    #9dedf0 12.19%,
    #7de7eb 29.82%,
    #5ce1e6 51.94%,
    #33bbcf 90.29%
  );
}

.bg-black-gradient {
  background: linear-gradient(144.39deg, #ffffff -278.56%, #6d6d6d -78.47%, #11101d 91.61%);
}

.bg-black-gradient-2 {
  background: linear-gradient(-168.39deg, #ffffff -278.56%, #6d6d6d -78.47%, #11101d 91.61%);
}

.bg-gray-gradient {
  background: linear-gradient(153.47deg, rgba(255, 255, 255, 0) -341.94%, #14101d 95.11%);
}

.bg-discount-gradient {
  background: linear-gradient(125.17deg, #272727 0%, #11101d 100%);
}

.box-shadow {
  box-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

.sidebar {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.feature-card:hover {
  background: var(--black-gradient);
  box-shadow: var(--card-shadow);
}

.feedback-container .feedback-card:last-child {
  margin-right: 0px;
}

.feedback-card {
  background: transparent;
}

.feedback-card:hover {
  background: var(--black-gradient);
}

.blue__gradient {
  background: linear-gradient(180deg, rgba(188, 165, 255, 0) 0%, #214d76 100%);
  filter: blur(123px);
}

.pink__gradient {
  background: linear-gradient(90deg, #f4c4f3 0%, #fc67fa 100%);
  filter: blur(900px);
}

.white__gradient {
  background: rgba(255, 255, 255, 0.6);
  filter: blur(750px);
}

.resize-gutter {
  width: 100%;
  height: 100%;
  /* display: block; */
  background: transparent;
  position: relative;
  cursor: ew-resize;
}

.resize-gutter::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 11px;
  height: 100%;
  /* display: block; */
  background: transparent;
  transform: translate(-50%, -50%);
  transition: all 0.15s;
  z-index: 50;
}

.resize-gutter:hover:after {
  width: 11px;
  height: 100%;
  right: -6px;
  /* border-radius: 6px; */
  background: #c7d2fe;
}

.z-9999 {
  z-index: 9999;
}

/* 提携ボタンの無効化　2022-1001。@applyでは効かない */

[class*='btn-'].disabled,
[class*='btn-']:disabled {
  background: #94a3b8;
}

[class*='btn-'].disabled:hover,
[class*='btn-']:disabled:hover {
  box-shadow: none;
}

input[disabled] {
  background: #f8fafc;
}

/* テキストのTop/Bottomをfade　2022-1111 */

.fade-text {
  mask-image: linear-gradient(transparent, black 10%, black 10%, transparent 100%);
  -webkit-mask-image: linear-gradient(transparent, black 10%, black 10%, transparent 100%);
}

/* 表示の最後をグラス効果 2022-1115 */

.element-card {
  position: relative;
}

/* element-card内のdocumentはpreで記述、但しproseの配下ではコメント 2022-1221 */

.prose .element-card pre {
  background: unset;
}

.element-card:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 2rem;
  left: 1px;
  bottom: 0;
  border-radius: 0 0 4px 4px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgb(255, 255, 255) 100%
  ) !important;
}

/* draw->svgは幅優先　高さは自動 2022-1129 */

.su-draw-svg svg {
  height: auto !important;
}

.su-style kbd {
  background-color: #e2e8f0;
  border-radius: 4px;
  border: 1px solid #cbd5e1;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), 0 2px 0 0 rgba(255, 255, 255, 0.7) inset;
  color: #475569;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI Adjusted', 'Segoe UI', 'Liberation Sans',
    sans-serif;
  display: inline-block;
  margin: 0 0.1em;
  padding: 3px 6px;
  font-size: 11px;
  line-height: 1.5;
  white-space: nowrap;
  box-shadow: 0 1px 1px hsl(210deg 8% 5% / 15%), inset 0 1px 0 0 hsl(0deg 0% 100%);
  overflow-wrap: break-word;
  text-shadow: 0 1px 0 white;
}

/* notionからHint 2022-1124 */

.bg-half-transparent {
  height: 100%;
  overflow: hidden auto;
  -webkit-mask-image: linear-gradient(black 80%, transparent 100%) !important;
}

/* bloombergから参考 20221124 */

.su-default-font {
  font-family: 'Hiragino Kaku Gothic Pro', Meiryo, 'MS Pgothic', Helvetica, Arial, Verdana,
    sans-serif;
}

.font-mono {
  font-family: 'Noto Sans Mono';
}

/* image resize 2022-1207 inspired by element-tiptap*/

.image-resizer {
  border: 1px solid #409eff;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.image-resizer .image-resizer__handler {
  background-color: #409eff;
  border: 1px solid #fff;
  border-radius: 2px;
  box-sizing: border-box;
  display: block;
  height: 12px;
  position: absolute;
  width: 12px;
  z-index: 2;
}

.image-resizer .image-resizer__handler--tl {
  cursor: nw-resize;
  left: -6px;
  top: -6px;
}

.image-resizer .image-resizer__handler--tr {
  cursor: ne-resize;
  right: -6px;
  top: -6px;
}

.image-resizer .image-resizer__handler--bl {
  bottom: -6px;
  cursor: sw-resize;
  left: -6px;
}

.image-resizer .image-resizer__handler--br {
  bottom: -6px;
  cursor: se-resize;
  right: -6px;
}

.grecaptcha-badge {
  visibility: hidden;
}
