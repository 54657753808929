@charset "UTF-8";
.video-js {
    font-size: 0.75rem;
    background-color: $darkmode-background !important;
}

.right-align {
    .vjs-menu {
        display: none;
        position: absolute;
        bottom: 0;
        width: 12.5rem !important;
        left: calc(3.25rem - 12.5rem) !important; // (Width of vjs-menu - width of button) / 2
        height: 0em;
        margin-bottom: 1.5em;
    }
}

.vjs-menu li.vjs-menu-title {
    padding: 0.35rem 0;
    font-size: 1rem;
    margin: 0 !important;
}

.vjs-menu li {
    padding: 0.25rem 0 0.25rem 0.75rem;
    line-height: 2rem;
    text-align: left;
}

.vjs-full-window .video-js.vjs-fullscreen {
    z-index: 5001 !important;
}

.vjs-playback-rate .vjs-playback-rate-value {
    font-size: 0.85rem;
    // color:#B3BCF5;
}

.vjs-playback-rate .vjs-menu {
    width: 4rem;
    left: 0em;
}

.vjs-playback-rate>.vjs-menu-button,
.vjs-playback-rate .vjs-playback-rate-value {
    position: absolute;
    top: 0.3125rem;
}

.video-js .vjs-current-time,
.video-js .vjs-time-divider,
.video-js .vjs-duration {
    display: block;
    padding: 0 0.25rem;
}

.vjs-time-control.vjs-time-divider {
    div {
        text-align: center;
        min-width: 0.25rem !important;
        margin: 0 !important;
        padding: 0 !important;
    }
}

// 非表示　2022-1022
.su-hidden-fullscreen {
    .vjs-fullscreen-control {
        display: none !important;
    }
}

// vjs-has-startedのStyleをshareup-customに適用。control-barを最初から表示。2021-9-19
// .vjs-has-started .vjs-control-bar {
//     display: flex;
//     visibility: visible;
//     opacity: 1;
//     transition: visibility 0.1s, opacity 0.1s;
// }
.shareup-custom .vjs-control-bar {
    display: flex;
    visibility: visible;
    opacity: 1;
    transition: visibility 0.1s, opacity 0.1s;
}

// 繰り返し再生のStyle　2021-09-19
.shareup-custom :not(.--inactive) {
    svg {
        color: white !important;
    }
}

.shareup-custom .--inactive {
    svg {
        color: #454f5b !important;
    }
}

.shareup-custom .--inactive {
    .vjs-menu {
        display: none !important;
    }
}

.shareup-custom .show-custom-menu {
    .vjs-menu {
        background-color: rgba(43, 51, 63, 0.7);
        position: absolute;
        right: 0;
        bottom: 2.25rem;
        width: 12.5rem;
        display: block !important;
        opacity: 1 !important;
        visibility: visible !important;
    }
}

// .video-js {
//     video[poster] {
//         object-fit: fill!important;
//     }
// }
// svg iconをcss-classで表示・切り替える方法。2021-09-20
.default-mode-icon {
    margin: auto;
    background-size: 57.5% !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg stroke='white' fill='white' stroke-width='0' viewBox='0 0 24 24' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M20,3H4C2.897,3,2,3.897,2,5v14c0,1.103,0.897,2,2,2h16c1.103,0,2-0.897,2-2V5C22,3.897,21.103,3,20,3z M4,19V5h16 l0.001,14H4z'%3e%3c/path%3e%3c/svg%3e") !important;
}

.theater-mode-icon {
    background-size: 57.5% !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg stroke='white' fill='white' stroke-width='0' viewBox='0 0 24 24' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M2 5H22V3H2V5Z' fill='white'%3e%3c/path%3e%3cpath d='M2 21H22V19H2V21Z' fill='white'%3e%3c/path%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2 7V17H22V7H2ZM4 9H20V15H4V9Z' fill='white'%3e%3c/path%3e%3c/svg%3e") !important;
}

@media (orientation: landscape) {
    .theater-mode {
        padding-top: 33.3% !important;
    }
    .theater-mode:not(.vjs-fullscreen) video {
        padding-top: 1.25rem !important;
        padding-bottom: 1.25rem !important;
    }
}

@media (orientation: portrait) {
    .theater-mode {
        padding-top: 56.25% !important;
    }
    .theater-mode:not(.vjs-fullscreen) video {
        padding-top: 0rem !important;
        padding-bottom: 0rem !important;
    }
}

// controlsBar icon幅 48->=youtube=40 2021-09-22
.video-js .vjs-control {
    width: 2.5rem;
}

.video-js .vjs-current-time,
.video-js .vjs-time-divider,
.video-js .vjs-duration {
    padding: 0 2px !important;
}

.video-js .vjs-time-control.vjs-time-divider div {
    max-width: 0.25rem !important;
    min-width: 0.25rem !important;
}

.video-js .vjs-time-control {
    min-width: 0.25rem !important;
}

// big-play-button
.vjs-paused {
    .vjs-big-play-button {
        display: block;
    }
}

//play button hidden
.hidden-play-button {
    .vjs-big-play-button {
        display: none !important;
    }
}

// playqueue-on/off-icon 2021-09-25
.playqueue-off-icon {
    margin: auto;
    background-size: 57.5% !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg stroke='currentColor' fill='white' stroke-width='0' viewBox='0 0 16 16' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M11 4a4 4 0 010 8H8a4.992 4.992 0 002-4 4.992 4.992 0 00-2-4h3zm-6 8a4 4 0 110-8 4 4 0 010 8zM0 8a5 5 0 005 5h6a5 5 0 000-10H5a5 5 0 00-5 5z' clip-rule='evenodd'%3e%3c/path%3e%3c/svg%3e") !important;
}

.playqueue-on-icon {
    background-size: 57.5% !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg stroke='currentColor' fill='white' stroke-width='0' viewBox='0 0 16 16' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M5 3a5 5 0 000 10h6a5 5 0 000-10H5zm6 9a4 4 0 100-8 4 4 0 000 8z' clip-rule='evenodd'%3e%3c/path%3e%3c/svg%3e") !important;
}

@media only screen and (max-width: 1399px - 0.02px) {
    .vjs-control-bar {
        padding-left: 0;
        padding-right: 0;
    }
}

@media only screen and (min-width: 1399px) {
    .vjs-control-bar {
        padding-left: 0.75rem !important;
        padding-right: 0.75rem !important;
    }
}

.vjs-modal-dialog .vjs-modal-dialog-content {
    font-size: 1.2em;
    line-height: 1.5;
    padding: 3rem;
}

// modalDialog style 2021-09-28
.shareup-dialog {
    max-width: 13.5rem !important;
    display: flex;
    justify-content: left;
    padding: 0.5rem;
    backdrop-filter: blur(3px);
    border-radius: 0.25rem;
    background: #021525;
    // background-image: -webkit-linear-gradient(90deg, #021525 0%, #021525 100%);
    // background-image: linear-gradient(90deg, #021525 0%, #021525 100%);
    .vjs-menu-content {
        min-width: 12.5rem;
    }
    &.--glass-gradient {
        background: none;
        background-image: linear-gradient( 90deg, rgba(2, 21, 37, 0.25) 0%, rgba(2, 21, 37, 0.75) 100%) !important;
    }
    &.--glass-25 {
        background: rgba(2, 21, 37, 0.25);
    }
    &.--glass-50 {
        background: rgba(2, 21, 37, 0.5);
    }
    &.--glass-75 {
        background: rgba(2, 21, 37, 0.75);
    }
}

.vjs-user-active .shareup-dialog {
    display: block;
}

.vjs-user-inactive .shareup-dialog {
    display: none;
}

// userのactionがない場合、control-bar非表示。再生する場合は除く。
// classは連続して記述しないと、効かない。
// 2021-11-10
.hidden-controlbar-inactive.vjs-paused.vjs-user-inactive {
    .vjs-control-bar {
        display: none;
    }
}

.hidden-controlbar-always {
    .vjs-control-bar {
        display: none;
    }
}