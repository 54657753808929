// react-flow:attribution hide . piao 2022-0319
.react-flow__attribution {
    display: none!important;
}

// 此処で、設定すると、styleでCustomizeする機能が効かなくなるので、しない。2022-0323
// .react-flow__node-default,
// .react-flow__node-group,
// .react-flow__node-input,
// .react-flow__node-output {
//     background: #fff;
//     border: 1px solid var(--theme-primary-50)!important;
//     border-radius: 3px;
//     color: #222;
//     font-size: 12px;
//     padding: 0.5rem!important;
//     text-align: center;
//     width: 150px;
// }
// handleStyle default 2022-0808
.touchdevice-flow .react-flow__handle {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #4b5563;
    transition: all 0.25s ease-in-out;
}

.touchdevice-flow .react-flow__handle.connecting {
    animation: bounce 1600ms infinite ease-out;
}

@keyframes bounce {
    0% {
        transform: translate(0, -50%) scale(1);
    }
    50% {
        transform: translate(0, -50%) scale(1.1);
    }
}