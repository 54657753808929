// default 10 2023-0115
.excalidraw.excalidraw-modal-container {
    z-index: 30 !important;
}

.excalidraw button.ExportDialog-imageExportButton svg {
    margin-left: auto !important;
    margin-right: auto !important;
}

.library-button__label {
    display: none !important;
}

.excalidraw .layer-ui__sidebar__header {
    padding: 0.75rem !important;
}

.excalidraw textarea.su-comment {
    border: unset !important;
}