/* DARK & LIGHT THEME: */

.os-scrollbar-horizontal {
  right: 10px;
  height: 10px;
}

.os-scrollbar-vertical {
  bottom: 10px;
  width: 10px;
}

.os-scrollbar-rtl.os-scrollbar-horizontal {
  left: 10px;
  right: 0;
}

.os-scrollbar {
  padding: 2px;
  box-sizing: border-box;
  background: transparent;
}

.os-scrollbar-unusable {
  background: transparent;
}

.os-scrollbar > .os-scrollbar-track {
  background: transparent;
}

.os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle {
  min-width: 30px;
}

.os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle {
  min-height: 30px;
}

.os-scrollbar-transition > .os-scrollbar-track > .os-scrollbar-handle {
  transition: background-color 0.3s;
}

.os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle,
.os-scrollbar > .os-scrollbar-track {
  border-radius: 10px;
}

.os-scrollbar:hover > .os-scrollbar-track > .os-scrollbar-handle {
  background: rgba(255, 255, 255, 0.55);
}

.os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle.active {
  background: rgba(255, 255, 255, 0.7);
}

.os-scrollbar-horizontal .os-scrollbar-handle:before,
.os-scrollbar-vertical .os-scrollbar-handle:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: block;
}

.os-scrollbar-horizontal .os-scrollbar-handle:before {
  top: -6px;
  bottom: -2px;
}

.os-scrollbar-vertical .os-scrollbar-handle:before {
  left: -6px;
  right: -2px;
}

.os-scrollbar-rtl.os-scrollbar-vertical .os-scrollbar-handle:before {
  right: -6px;
  left: -2px;
}

.os-theme-rainy-ashville.os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
  border-radius: 0.375rem;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-gradient(linear, left bottom, left top, from(#fbc2eb), to(#a6c1ee));
  background-image: -webkit-linear-gradient(bottom, #fbc2eb 0%, #a6c1ee 100%);
  background-image: linear-gradient(to top, #fbc2eb 0%, #a6c1ee 100%);
}

.os-theme-frozen-dreams.os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
  border-radius: 0.375rem;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-gradient(linear, left bottom, left top, from(#fbc2eb), to(#a6c1ee));
  background-image: -webkit-linear-gradient(bottom, #fbc2eb 0%, #a6c1ee 100%);
  background-image: linear-gradient(to top, #fbc2eb 0%, #a6c1ee 100%);
}

.os-theme-warm-flame.os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
  border-radius: 0.375rem;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%);
  background-image: linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%);
}

.os-theme-tempting-azure.os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
  border-radius: 0.375rem;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-linear-gradient(330deg, #84fab0 0%, #8fd3f4 100%);
  background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
}

.os-theme-amy-cris.os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
  border-radius: 0.375rem;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-linear-gradient(330deg, #a6c0fe 0%, #f68084 100%);
  background-image: linear-gradient(120deg, #a6c0fe 0%, #f68084 100%);
}

.os-theme-mean-fruit.os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
  border-radius: 0.375rem;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-linear-gradient(330deg, #fccb90 0%, #d57eeb 100%);
  background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
}

.os-theme-deep-blue.os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
  border-radius: 0.375rem;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-linear-gradient(330deg, #e0c3fc 0%, #8ec5fc 100%);
  background-image: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%);
}

.os-theme-morpheus-de.os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
  border-radius: 0.375rem;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-gradient(linear, left bottom, left top, from(#30cfd0), to(#330867));
  background-image: -webkit-linear-gradient(bottom, #30cfd0 0%, #330867 100%);
  background-image: linear-gradient(to top, #30cfd0 0%, #330867 100%);
}

.os-theme-near-moon.os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
  border-radius: 0.375rem;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-gradient(linear, left bottom, left top, from(#5ee7df), to(#b490ca));
  background-image: -webkit-linear-gradient(bottom, #5ee7df 0%, #b490ca 100%);
  background-image: linear-gradient(to top, #5ee7df 0%, #b490ca 100%);
}

.os-theme-gradient-slate.os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
  border-radius: 0.375rem;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-gradient(linear, left bottom, left top, from(#e2e8f0), to(#cbd5e1));
  background-image: -webkit-linear-gradient(bottom, #e2e8f0 0%, #cbd5e1 100%);
  background-image: linear-gradient(to top, #e2e8f0 0%, #cbd5e1 100%);
}

.os-theme-slate-300.os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
  border-radius: 0.375rem;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background: #cbd5e1;
}

.os-theme-sky-300.os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
  border-radius: 0.375rem;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background: #7dd3fc;
}

.os-theme-indigo-300.os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
  border-radius: 0.375rem;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background: #a5b4fc;
}

.os-theme-violet-300.os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
  border-radius: 0.375rem;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background: #c4b5fd;
}
