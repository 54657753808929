.swiper.portal-home-swiper {
    width: 100%;
    padding-bottom: 1.75rem;
}

// .swiper-slide {
//     width: 20rem!important;
// }
.swiper-slide img {
    display: block;
    width: 100%;
}

.category-area {
    padding: 1rem 0.5rem;
    display: inline-block;
    vertical-align: middle;
}

.category-title {
    font-size: 1.5rem;
    color: #1e40af;
    font-weight: bold;
}

// .category-title:before,
// .category-title:after {
//     content: '';
//     width: 2rem;
//     height: 2px;
//     display: inline-block;
//     background-color: $gray-400;
//     margin: 0 1rem;
//     -webkit-transform: translateY(-6px);
//     -ms-transform: translateY(-6px);
//     transform: translateY(-6px);
// }
.category-title-sub {
    font-size: 0.85rem;
    color: #1e40af;
    // color: #455873;
    margin-right: 16px;
}