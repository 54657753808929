.su-reverse .wb-body {
    overflow: hidden;
}

.su-reverse .wb-header {
    // transition: box-shadow 0.3s;
    // box-shadow: 0px 0px 8px 0px rgb(111 123 203 / 20%) !important;
    .wb-title {
        font-size: 0.9rem !important;
        font-weight: 500 !important;
        color: #64748b;
    }
    .wb-control {
        .wb-min,
        .wb-max,
        .wb-full,
        .wb-close {
            filter: invert(0.6);
        }
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

.wb-header {
    border-bottom: 1px solid #f1f5f9;
    z-index: 10 !important;
}

.wb-custom {
    background-size: 20px auto;
    margin-right: 0.75rem;
}

.su-reverse {
    .library-button__label {
        display: none !important;
    }
}

.winbox.su-reverse {
    box-shadow: 0 0 32px 0 rgb(148 163 184 / 0.5) !important;
}